#infoLogo {
    width: 500px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.logo {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@keyframes infoAnimation {
  0% {
    opacity: 0;
    top: 50vh;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

#infoContainer {
    position: relative;
    top: 0;
    text-align: center;
    color: #ffffff;
    animation: 4s ease-in 0s 1 infoAnimation;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

#infoContainer h2 {
    font-family: 'Crimson Pro', serif;
    font-size: 27px;
}

#infoContainer .title {
    font-size: 13px;
    font-weight: 100;
}

#infoContainer a {
    display: inline-block;
    padding: 8px;
    margin: 10px 2px 0 2px;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #721DD5;
    border-radius: 10px;
    width: calc(50% - 24px);
}

#infoContainer a:hover {
    background-color: transparent;
    color: #ffffff;
    font-weight: 700;
}

@media screen and (max-width: 1150px) {

    @keyframes logoAnimation {
        0% {
          height: 100%;
        }
        100% {
            height: 64px;
        }
      }

    #infoLogo {
        width: auto;
        max-height: 100%;
        height: 64px;
        animation: 4s ease-in 0s 1 logoAnimation;
    }

    #infoContainer {
        display: none;
    }

    .logo {
        top: 50%;
        position: relative;
        transform: translate(0, -50%);
    }

}
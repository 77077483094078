* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  background: linear-gradient(to top, #dbc6f3, #ffffff 50%);
  transition: background-color 1s ease-out;
  width: 100%;
  height: 100vh;
}

@keyframes containerAnimation {
  0% {
    max-width: 100%;
  }
  100% {
    max-width: 2600px;
  }
}

#container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2600px;
  height: 100vh;
  position: relative;
  animation: 4s ease 0s 1 containerAnimation;
  overflow: hidden;
}

@keyframes logoContainerAnimation {
  0% {
    right: 0;
    border-radius: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    right: 75%;
    border-radius: 20px;
    top: 20px;
    bottom: 20px;
    left: 20px;
  }
}

#infoLogoContainer {
  background-color: #721DD5;
  background: linear-gradient(150deg, #721DD5 25%, #1d74d5);
  position: absolute;
  z-index: 100;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 75%;
  border-radius: 20px;
  animation: 4s ease 0s 1 logoContainerAnimation;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@keyframes projectcontainerAnimation {
  0% {
    opacity: 0%;
    left: 100%;
  }
  40% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
    left: 25%;
  }
}

#projectcontainer {
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  bottom: 0;
  left: 25%;
  padding: 50px;
  opacity: 100%;
  overflow-y: auto;
  animation: 4s ease-out 0s 1 projectcontainerAnimation;
}

@media screen and (max-width: 1150px) {
  
  @keyframes logoContainerAnimation {
    0% {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      border-radius: 0;
    }
    100% {
      top: 9px;
      left: 9px;
      right: 9px;
      height: 64px;
      border-radius: 20px;
    }
  }

  @keyframes projectcontainerAnimation {
    0% {
      opacity: 0%;
      top: 100vh;
    }
    40% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
      top: 70px;
    }
  }

  #infoLogoContainer {
    top: 9px;
    left: 9px;
    right: 9px;
    height: 64px;
    bottom: auto;
    border-radius: 20px;
    padding: 10px 24px;
    text-align: center;
  }

  #projectcontainer {
    left: 0;
    top: 70px;
    padding: 30px;
  }
}